#check {
    padding: .5rem;
  }
  
  .input_wrapper{
    margin-bottom: 1.1rem;
  }
  
  #check option{
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 1rem;
  }
  
  
  .phoneField{
    display: flex;
    align-items: center;
    border: 1px solid #737070;
    width: 100%;
    border-radius: 7px;
    border: 1px solid #E8E8E8;
    padding: .25rem;
  }
  
  .phoneFieldInput{
    border: none;
    outline: none;
    margin-left: .3rem;
    margin-bottom: .1rem;
  }
  
  .phoneFieldCode{
    font-size: 14px;
    margin-left: .3rem;
  }
  
  #myImg{
    margin-left: .3rem;
    display: block;
    width: 30px;
    height: 30px;
  }
  
  #check{
    width: 35px;
    height: 26px;
    opacity: 0;
    position: absolute;
  }
  
  
  .countrySelect{
    display: flex;
    position: relative;
  }
  
  
  .label_styles {
    font-weight: 600;
    font-size: 13px;
    font-family: "Figtree";
  }
  