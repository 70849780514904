
.loaderbody {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-color: #010914;
    position: absolute;
    width: 100%;
    z-index: 10000000;
    opacity: 0.5;
	display: none;
}
.loaderbodyforQuote{
	display: flex !important; 
    justify-content: center;
    align-items: center;
    min-height: 69vh;
    /* background-color: #d6d8db; */
    position: absolute;
    width: 100%;
    z-index: 1000;
    opacity: 0.5;
    /* display: none; */
}
.loaderbodyforQuote .plane .icon  {
	color: #000;
}
.loaderbodyforQuote .loader1 span::before{
	background-color: #000;
}
.loader1 {
	position: relative;
	width: 100px;
	height: 100px;
}

.loader1 span {
	position: absolute;
	width: 100%;
	height: 100%;
	transform: rotate(calc(18deg * var(--i)));
}
.loader1 span::before {
	content: "";
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	transform: scale(0);
	animation: load 2s linear infinite;
	animation-delay: calc(0.1s * var(--i));
}
@keyframes load {
	0% {
		transform: scale(0);
	}
	10% {
		transform: scale(1.2);
	}
	80%,
	100% {
		transform: scale(0);
	}
}
.plane {
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	height: 100px;
	animation: rotate 2s linear infinite;
	animation-delay: -1.5s;
}
@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.plane .icon {
	font-size: 50px;
	color: #fff;
	position: absolute;
	top: -20px;
	left: 80px;
	transform: rotate(45deg);
}
