/* styles.css */
.table-container {
    overflow: auto;
    margin: 20px;
    background-color: white;
  }
  
.table-container::-webkit-scrollbar-thumb{
    background-color: #2A6ED4 !important;
}

  .excel-table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
  }
  
  .excel-table th,
  .excel-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    min-width: 50px; /* Ensure minimum width for better visibility */
  }
  
  .excel-table th {
    font-weight: bold;
    color: black;
  }
  
  /* .excel-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .excel-table tr:hover {
    background-color: #ddd;
  } */
  