.role-badge{
    /* border-radius: 0px 35px 35px 0px; */
    padding:.5rem;
    background-color: rgb(136, 136, 136); 
    color: white; 
    width: 120px;
    border: 1px solid rgb(187, 187, 187); 
    /* margin: 1px; */
  }


.user-roles-badge-selected{
    background-color: rgb(44, 53, 143);
    color: white;
    border: 1px solid rgb(44, 53, 143);
  }


  /* body{
    display: none;
  } */
