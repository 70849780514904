.scroll-table{
    display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
}
.w-200{
    width: 200px;
    display: flex;
}