@import url('https://fonts.googleapis.com/css2?family=Figtree&display=swap');
.signIN_container{
    display: flex;
    min-height: 100vh;
    height: 100%;
    padding: 1rem;
    font-family: 'Figtree', sans-serif;
}
.info_container{
    background-color: #F2F4F8;
    min-height: 100%;
    width: 40%;
    padding: 2rem;
    border-radius: 10px;
    /* margin: 1rem; */
}
.field_container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
}
.ref{
    color: var(--Black, #000);

font-family: Figtree;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 125% */
}
.field_wrapper{
    width: 60%;
    background-color: #fefefe;
    padding: 1rem;
    align-items: center;
    flex-direction: column;
}
.inputBox_styles{
border-radius: 7px;
border: 1px solid #E8E8E8;
padding: .7rem;
background: #FFF;
width: 100%;
outline: none;
font-family: 'Figtree', sans-serif;

}
.input_wrapeer{
    margin-bottom: 1.1rem;

}
.label_styles{
    font-weight: 600;
    font-size: 13px;
}

.clr_blue{
    color: #2B6ED4;
}

.fogot_password{
    float: right;
    font-family: Figtree;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.note_info{
color: #555;
leading-trim: both;
text-edge: cap;
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 187.5% */
position: absolute;
bottom: 2%;
}
.note_info2{
  color: #555;
leading-trim: both;
text-edge: cap;
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 187.5% */
}

.note_info .clr_blue ,.note_info2 .clr_blue {
    font-weight: 700;
    cursor: pointer;
}
.btn_color{
  background-color:  #2B6ED4 !important;
}

.btn_no_color {
  background-color: transparent;
  border: 1px solid #2B6ED4 !important; 
  color: #ccc; 
  font-weight: bold;
  border-width: 2px;
}

.Remember_me{
    color: var(--Black, #000);
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.logo_container{
    margin-bottom: 2rem;
}

.logo_container img{
    width: 170px;
height: 45px;
}
.mail_container{
    text-align: center;
}
.mail_container img{
    /* width: 570px; */
    height: 245px;  
}
.onboard_logo{
    width: 100vw;
    height: 100vh;
}
.onboard_logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.colorGray{
    color: #555;
}
.card_container{
    background-color: #2D3346;
    border-radius: 10px;
    background: #2D3346;
    height: 250px;
    color: #fff;
    margin-top: 1rem;
    overflow: hidden;
    position: relative;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
}
.card_container .qoute_logo{
    position: absolute;
    color: #424759;
    left: 8%;
    height: 60px;
}
.userFeedback_container{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 0 3.5rem;
    gap: 1rem;
}
.userFeedback_img{
    width: 60px;
    height: 60px;
}
.userFeedback_img img{
  display: inline-block;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  box-shadow: 5px 5px 5px #080808;
  background-color: #fff;
  object-fit: contain;
  object-position: center;
}
.PhoneInputInput{
    border: none;
    outline: none;
}

.onboardForm{
    border-radius: 10px;
    border: 1px solid #E8E8E8;
    background: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 602px;
    min-height: 400px;
}
.info_logo{
    position: absolute;
    top: -8%;
    left: 50%;
    transform: translate(-50%, -0%);
}

  .carousel {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
  }
  .carousel__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 1.5rem;
    border: none;
    padding: 1rem;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s;
  }
  .carousel__btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .carousel__btn--prev {
    left: 0;
  }
  .carousel__btn--next {
    right: 0;
  }
  .carousel__img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease-in-out;
  }
  /* .carousel__img:hover {
    transform: scale(1.05);
  } */

  .carousel__indicators{

    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    margin: 1rem;
  }

  .carousel__indicator{
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color:  #AAAAAA;
    border-radius: 50%;
    cursor: pointer;
  }


  .carousel__indicators .active{
    background-color: #2B6ED4;
  }

  .input_icon_styles{
    display: flex;
    border: 1px solid #E8E8E8;
    gap: 0.5rem;
    border-radius: 7px;
    align-items: center;
    cursor: pointer;
  }

  .input_icon_styles .inputBox_styles{
    border: none;
    width: 90%;
  }

  .input_icon_styles .width85{
    width: 85%;
  }



  .password_wrapper{
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    margin-bottom: 0.7rem;
  }

  .password_wrapper p{
    font-size: 10px;
    color: red;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .selectBox_styles{
    width: 100%;
    border: 1px solid #E8E8E8;
    border-radius: 7px;
  }

  .select__control {
    /* outline: 0!important; */
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: transparent !important;
    /* border-color: #fff; */
    border-radius: 4px;
    /* border-style: solid; */
    border-width: 0 !important;
    box-sizing: border-box;
}

.css-t3ipsp-control{
  border-width: 0 !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.border_red{
  border: 1px solid #F25B5B;
}

.edgeEyeHide::-ms-reveal,
.edgeEyeHide::-ms-clear {
  display: none;
}
#ReplaceText{
  font-size: 13.2px;
  height: 17.4px;
  margin-left: 10px;
  font-weight: 500;
}

.btm20{
  bottom: 20%;
}

.divider {
  width: 100%;
  text-align: center;
  border-top: 1px dashed lightgray;
  line-height: 0.1em;
  margin: 20px 0;
  position: relative;
}

.divider-text {
  position: absolute;
  top: -12px; /* Adjust based on your line height */
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 0 10px;
  font-weight: bold;
  color: #000;
}
.marginBottom1rem{
  margin-bottom: 1.1rem !important;
  width: 50% !important;
}
.signup_wrapper{
    width: 80%;
}
.signup_container{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: column; */
  gap: 1rem;
}
.signup_container .input_wrapeer{
  width: 50%;
}
/* .signup_field_wrapper{
  display: flex;
  gap: 1rem;
} */
.logo_container_for_mobile{
  display: none;
}
.verify_for_mobile{
  width: 40%;
}
.sm_phoneField{
  width: 50%;
}
@media (max-width: 600px) { 
  .info_container{
    display: none;
  }
  .field_container{
    width: 100%;
  }
  .field_wrapper{
    width: 100%;
  }
  .signup_wrapper{
    width: 100%;
  }
  .signup_container{
    display: block;
    width: 100%;
  }
  .signup_container .input_wrapeer{
    width: 100%;
    margin-bottom: .5rem;
  }
  .note_info{
    position: static;
  }
  /* .signup_field_wrapper{
    display: block;
  } */
  .logo_container_for_mobile{
    display: block;
    width: 100%;
    padding: 0 1rem;
  }
  .verify_for_mobile{
    width: 100%;
  }
  .sm_none{
    display: none;
  }
  .marginBottom1rem{
    margin-bottom: 0.5rem !important;
    width: 100% !important;
  }
  .sm_phoneField{
    width: 100%;
  }
}


.graphForMobile {
  scrollbar-width: thin; 
  scrollbar-color: #2D48A6 #2D48A6; 
}

.graphForMobile::-webkit-scrollbar {
  height: 8px; 
}

.graphForMobile::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.graphForMobile::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.graphForMobile::-webkit-scrollbar-track {
  background: #f5f5f5; 
}