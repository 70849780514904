

@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Hanken Grotesk", "sans-serif!important";
    --primary-color: #2C358A;
    --secondary-color: #fff;
    --tertiary-color: #f5f5f5;
    --border-1: #D8D9DA
}

.d-flex {
    display: flex !important
}
.d-none {
  display: none !important
}

.section-padding {
    padding: 0 10px 15px 10px !important
}
.section{
  padding:0
}
.margin-none {
    margin: 0 0 !important
}

.margin-top {
    margin-top: 20px !important
}

.margin-bottom-2 {
    margin-bottom: 10px !important
}

.margin-bottom-3 {
    margin-bottom: 30px !important
}

.height-45 {
    height: 40px !important
}

.width-200 {
    width: 200px !important
}

.background-none {
    background: none !important
}

.background-white {
    background: #fff !important
}

.visibility-hidden {
    visibility: hidden !important
}

.main-content-agraga {
    padding-left: 80px;
    width: 100%;
    height: auto
}

.main-content .header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding: 10px; */
  margin: 15px 10px ;
}

.main-content .header-wrapper .left-header {
    display: flex
}

.main-content .header-wrapper .left-header .back-div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

.main-content .header-wrapper .left-header .back-div .back {
    width: 36px;
    height: 36px
}

.main-content .header-wrapper .left-header .page-heading {
    display: flex;
    align-items: center;
    margin: 0 10px
}

.main-content .header-wrapper .left-header .page-heading .heading, .main-content .header-wrapper .left-header .page-heading .slash {
  font-size: 24px;
  font-weight: 700;
  padding: 0 2px;
  text-transform: capitalize !important;
}

.main-content .header-wrapper-2 {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* margin: 0 0 15px;
    padding: 0 40px */
}

.main-content .header-wrapper-2 .left-header-2 {
    display: flex
}

.main-content .header-wrapper-2 .left-header-2 .status-label {
    font-size: 20px;
    font-weight: 700;
    color: #000
}

.main-content .header-wrapper-2 .left-header-2 .status-value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    height: 30px;
    padding: 5px 10px;
    margin: 0 10px;
    background: #e6e6e6;
    border-radius: 50px;
    outline: 2px solid #aaa
}

.main-content .header-wrapper-2 .left-header-2 .status-value .status-text {
    color: #aaa;
    font-size: 16px
}

.main-content .header-wrapper-2 .right-header-2 {
    display: flex;
    background: #f5f5f5;
    border-radius: 10px;
    padding: 5px 8px
}

.main-content .header-wrapper-2 .right-header-2 div {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    border-radius: 8px;
    cursor: pointer
}

.main-content .header-wrapper-2 .right-header-2 .booking-tab {
    background: #2c358a
}

.main-content .header-wrapper-2 .right-header-2 .booking-tab .switch-text {
    color: #2c358a
}

.main-content .header-wrapper-2 .right-header-2 img {
    width: 24px;
    height: 24px
}

.main-content .header-wrapper-2 .right-header-2 .switch-text {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #fff
}

.main-content .blue-heading {
    color: #2c358a;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 8px
}

.main-content .fields-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    /* height: 66px; */
}

.main-content .fields-wrapper .field {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 240px;
}
.main-content .is-danger{
  color: #f14668!important;
}

.is-danger-border{
  border: 2px solid #e50303!important;
}

element.style {
  margin-left: 3px;
}
.main-content .is-danger {
  color: #f14668!important;
}
.main-content .button.is-danger {
  background-color: #f14668;
  border-color: transparent;
  color: #fff!important;
}
.main-content1 .is-danger{
  border: 2px solid #e50303!important;
}
.main-content2 .is-danger{
  border: 2px solid #e50303!important;
}
.is-input-danger-message{
  color: #e50303!important;
}
.is-input-success-message{
  color:#008000!important;
}
.is-input-danger{
  border: 2px solid #e50303!important;
}
.is-select-danger{
  border: 2px solid #e50303!important;
}
.main-content .fields-wrapper .field .input-field {
  width: 240px;
  height: 40px;
  border-radius: 8px;
  outline: 2px solid #d8d9da;
  padding: 0 6px 0 12px;
  color: #3a3a3a;
  font-size: 16px;
  margin-bottom: 20px;
  border: none;
}

.main-content .fields-wrapper .field input:focus {
    outline: 2px solid #2c358a;
    border: none
}

.main-content .fields-wrapper .field .input-disabled {
    width: 220px !important;
    height: 40px !important;
    border-radius: 10px;
    outline: 0;
    display: flex;
    align-items: center;
    color: #3a3a3a;
    font-size: 16px;
    /* margin-bottom: 20px; */
    border: none;
    padding: 0 6px 0 15px;
    text-overflow: ellipsis;
    font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.main-content .filler {
    width: 250px;
    height: auto
}

.main-content .filler-2 {
    width: 250px;
    height: 45px
}

.main-content .filler-3 {
    width: 45px;
    height: 45px
}

.main-content .highlighted-text {
  width: 240px;
  height: 40px;
  border-radius: 10px;
  outline: 0;
  padding: 0 15px;
  color: #2c358a;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  border: none;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.main-content .highlighted-text span {
  font-size: 12px;
  font-weight: 400;
  color: #3a3a3a;
}

.main-content .input-padding-left {
    padding-left: 55px !important;
}

.main-content .input-padding-right {
    padding-left: right !important
}

.main-content .input-c-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.main-content .input-d-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.main-content .country-code {
    background: #1B6B93;
    outline: 2px solid #1B6B93;
    width: 50px;
    padding-top: 3px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 10px 0 0 10px;
    position: absolute
}

.width-220{
  width: 220px !important;
}

.font-15{
  font-size: 15px !important;
}

.main-content .input-dropdown {
    width: 70px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1B6B93;
    color: #fff;
    border-radius: 0 10px 10px 0;
    position: absolute;
    outline: 2px solid #1B6B93;
    cursor: pointer
}

.main-content .chevron-3 {
    width: 15px;
    height: auto;
    margin: 0 5px
}

.main-content .currency-div {
    width: 80px;
    height: 200px;
    background: #f5f5f5;
    outline: 2px solid #d8d9da;
    border-radius: 5px;
    position: absolute;
    transform: translate(-3px, 135px);
    overflow-y: scroll;
    overflow-x: hidden
}

.main-content .currency-div ul {
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center
}

.main-content .currency-div ul li {
    padding: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3a3a3a;
    border-radius: 5px
}

.main-content .currency-div ul li:hover {
    color: #fff;
    background: #2c358a
}

.file-div span a{
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
}

.main-content .currency-div::-webkit-scrollbar {
    width: 8px
}

.main-content .currency-div::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #f5f5f5
}

.main-content .currency-div::-webkit-scrollbar-thumb {
    background: #2c358a;
    border-radius: 10px
}

.main-content .wrapper, .main-content .file-input-div, .main-content .file-div {
    height: 50px;
    width: 250px;
    display: flex;
    flex-direction: column
}

.main-content .file-placeholder {
    width: 250px;
    height: 50px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    color: #3a3a3a;
    font-size: 16px
}

.main-content [type=file] {
    height: 0;
    overflow: hidden;
    width: 0
}

.main-content [type=file]+label {
    background: #fff;
    color: #2c358a;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    outline: 2px dashed #2c358a;
    width: 240px;
    height: 40px;
    border-radius: 10px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
    text-align: center
}

.main-content [type=file]+label:hover {
    background-color: rgba(100, 110, 193, 0.2549019608)
}

.main-content [type=file]+label.btn-3 {
    background-color: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center
}

.main-content [type=file]+label.btn-3 span {
    display: flex;
    align-items: center;
    display: inline-block;
    transition: all 0.3s
}

.main-content [type=file]+label.btn-3 .upload-img {
    position: absolute;
    width: 24px;
    height: 24px;
    transform: translateY(-50px)
}

.main-content [type=file]+label.btn-3:hover .upload-img {
    transform: translateY(0px)
}

.main-content [type=file]+label.btn-3:hover span {
    transform: translateY(50px)
}

.main-content [type=file]+label.btn-4 {
    background-color: #1B6B93;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    outline: 0
}

.main-content [type=file]+label.btn-4 span {
    display: flex;
    align-items: center;
    display: inline-block;
    transition: all 0.3s
}

.main-content [type=file]+label.btn-4 .upload-img {
    position: absolute;
    width: 24px;
    height: 24px;
    transform: translateY(-50px)
}

.main-content [type=file]+label.btn-4:hover .upload-img {
    transform: translateY(0px)
}

.main-content [type=file]+label.btn-4:hover span {
    transform: translateY(50px)
}

.main-content [type=file]+label.btn-5 {
    background-color: #EA9A96;
    overflow: hidden;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    outline: 0
}

.main-content [type=file]+label.btn-5 span {
    display: flex;
    align-items: center;
    display: inline-block;
    transition: all 0.3s
}

.main-content [type=file]+label.btn-5 .upload-img {
    position: absolute;
    width: 24px;
    height: 24px;
    transform: translateY(-50px)
}

.main-content [type=file]+label.btn-5:hover .upload-img {
    transform: translateY(0px)
}

.main-content [type=file]+label.btn-5:hover span {
    transform: translateY(50px)
}

.main-content .file-img {
    width: 24px;
    height: 24px;
    margin-right: 10px
}

.main-content .file-div {
    width: 250px;
    height: 50px;
    border-radius: 10px;
    color: #3a3a3a;
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    flex-direction: row
}

.main-content .file-div span {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 15px
}

.main-content .file-div-2 {
    width: 250px;
    height: 50px;
    border-radius: 10px;
    color: #3a3a3a;
    background: #fff;
    outline: 1px solid #d8d9da;
    display: flex;
    overflow: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-y: hidden;
    justify-content: space-between;
    overflow-x: hidden;
}

.main-content .file-div-2 span {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 15px
}

.main-content .cancel-div {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.main-content .cancel-button {
    width: 24px;
    height: 24px
}

.main-content .section {
    width: 100%
}

.main-content .address-list {
    outline: 2px solid #d8d9da;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    overflow-x: scroll;
    overflow-y: hidden;
    width: inherit
}

.main-content .address-list .add-block {
    display: flex;
    flex-direction: column
}

.main-content .address-list .add-block .add-heading {
    font-size: 16px;
    color: #3a3a3a;
    font-weight: 600;
    margin-bottom: 5px
}

.main-content .address-list .add-block .add-div {
    padding: 10px;
    width: 250px;
    background: #f5f5f5;
    border-radius: 10px
}

.main-content .address-list .add-block .add-div .address {
    font-size: 14px;
    color: #3a3a3a;
    font-weight: 500
}

.main-content .address-list .thread-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 80px
}

.main-content .address-list .thread-div .thread {
    width: inherit;
    height: 1px;
    background: repeating-linear-gradient(90deg, #d8d9da 0 5px, #fff 0 12px);
    border-radius: 1px
}

.main-content .address-data-div {
    background: #f5f5f5;
    border-radius: 15px;
    padding: 10px 10px 10px 20px;
}

.main-content .address-data-div .address-field-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
}

.main-content .address-data-div .address-field-row .add-block {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
}

.main-content .address-data-div .address-field-row .add-block .add-heading {
    font-size: 15px;
    color: #000;
    font-weight: 600;
    margin-bottom: 5px
}

.main-content .address-data-div .address-field-row .add-block .add-div {
    width: 250px;
    background: #f5f5f5;
    border-radius: 10px;
    padding-right: 10px;
}

.main-content .address-data-div .address-field-row .add-block .add-div .address {
    font-size: 14px;
    color: #3a3a3a;
    font-weight: 500
}

.main-content .address-data-div .address-field-row .field {
    display: flex;
    flex-direction: column
}

.main-content .address-data-div .address-field-row .field .input-label {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
  width: 240px;
}

.main-content .address-data-div .address-field-row .field .input-field {
  width: 240px;
  height: 40px;
  border-radius: 10px;
  outline: 2px solid #d8d9da;
  padding: 0 15px;
  color: #3a3a3a;
  font-size: 16px;
  margin-bottom: 20px;
  border: none;
}

.main-content .address-data-div .address-field-row .field .input-disabled {
    width: 250px;
    height: 50px;
    border-radius: 10px;
    outline: 0;
    color: #3a3a3a;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 10px;
    border: none;
    text-overflow: ellipsis
}

.main-content .address-data-div.width-100 {
    width: 100%
}

.main-content .address-data-div.width-100 .address-field-row {
    width: 100%
}

.main-content .contact-outer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    width: 100%
}

.main-content .contact-field-wrapper {
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    width: inherit;
    /* justify-content: space-between; */
    display: flex;
    align-items: flex-start;
    width: inherit;
    justify-content: space-around
}

.main-content .delete-div {
    width: 45px;
    height: 45px;
    border-radius: 8px;
    background: #FE3839;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    cursor: pointer;
    margin-right: -2.5%;
}

.main-content .delete-div .delete-contact {
    width: 24px;
    height: 24px
}

.main-content .add-contact {
  position: relative;
    height: 40px;
    width: 240px;
    margin: 20px 0 0;
    background: #A089BD;
    color: #fff;
    display: flex;
    align-items: center;
    border: none;
    justify-content: center;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.font-18px{
  font-size: 18px;
}
.main-content .add-contact:hover {
    background: #7959A1
}

.main-content .add-contact .add-contact-img {
    width: 24px;
    height: 24px;
    margin-right: 15px
}

.main-content .footer-div-wrapper {
    display: flex;
    width: 100%;
    gap: 5%
}

.main-content .footer-div-wrapper .left-wrapper {
    width: 47.5%
}

.main-content .footer-div-wrapper .left-wrapper .upload-section {
    margin-top: 15px;
    background: #f5f5f5;
    min-height: 400px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between
}

.main-content .footer-div-wrapper .left-wrapper .dotted-border {
    width: 1px;
    height: 360px;
    background: repeating-linear-gradient(0deg, #d8d9da 0 5px, #f5f5f5 0 12px)
}

.main-content .footer-div-wrapper .right-wrapper {
    width: 47.5%
}

.main-content .footer-div-wrapper .right-wrapper .textarea-section {
  margin-top: 6px;
  background: #f5f5f5;
  min-height: 140px;
  border-radius: 0px;
  padding: 0px;
  display: flex;
}

.main-content .footer-div-wrapper .right-wrapper .textarea-section .text-area {
  width: 100%;
  height: 139px;
  border: none;
  outline: 2px solid #d8d9da;
  border-radius: 15px;
  resize: none;
  font-size: 16px;
  color: #3a3a3a;
  font-weight: 500;
  padding: 11px;
}

.main-content .footer-div-wrapper .right-wrapper .textarea-section::-moz-placeholder {
    color: #3a3a3a;
    font-size: 16px;
    font-weight: 600
}

.main-content .footer-div-wrapper .right-wrapper .textarea-section::placeholder {
    color: #3a3a3a;
    font-size: 16px;
    font-weight: 600
}

.main-content .standard-terms-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    border-radius: 10px;
    background: #f5f5f5;
    cursor: pointer
}

.main-content .standard-terms-div .st-inner-text {
    color: #2c358a;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 600
}

.main-content .standard-terms-div .st-inner-text .chevron-4 {
    width: 18px;
    margin: 1px 0 0 10px;
    height: auto;
    transform: rotate(90deg)
}

.main-content .buttons-div {
  width: 100%;
  margin: 5px 0 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
}

.main-content .buttons-div .cancel-form-button {
    padding: 10px 20px;
    width: -moz-fit-content;
    width: fit-content;
    height: 40px;
    background: #f5f5f5;
    color: #2c358a;
    outline: 2px solid #d8d9da;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 800;
    cursor: pointer
}

.main-content .buttons-div .cancel-form-button span {
    display: flex;
    align-items: center;
    justify-content: center
}

.main-content .buttons-div .cancel-form-button .cancel-form {
    width: 14px;
    height: 14px;
    margin-right: 10px
}

.main-content .buttons-div .submit-form-button {
    padding: 10px 20px;
    width: -moz-fit-content;
    width: fit-content;
    height: 40px;
    background: #2c358a;
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer
}

.main-content .buttons-div .submit-form-button span {
    display: flex;
    align-items: center;
    justify-content: center
}

.main-content .buttons-div .submit-form-button .submit-form {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    width: 18px;
    height: 18px;
    margin-right: 10px
}

.main-content .vehicle-dropdown-div {
    position: absolute;
    width: 250px;
    height: 300px;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(0px, 85px)
}

.main-content .vehicle-dropdown-div .vehicle-list {
    width: 100%
}

.main-content .vehicle-dropdown-div .vehicle-list ul {
    height: 225px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 230px;
    overflow-x: hidden;
    overflow-y: scroll;
    gap: 10px
}

.main-content .vehicle-dropdown-div .vehicle-list ul li {
    display: flex;
    align-items: center;
    height: 45px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    color: #3a3a3a;
    padding: 10px 20px
}

.main-content .vehicle-dropdown-div .vehicle-list ul li:hover {
    background: #2c358a;
    color: #fff
}

.main-content .vehicle-dropdown-div .add-vehicle-button {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    background: #A089BD;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 10px 0;
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer
}

.main-content .vehicle-dropdown-div .add-vehicle-button span {
    margin: 0 10px
}

.main-content .vehicle-dropdown-div .add-vehicle-button:hover {
    background: #7959A1
}

.main-content .vehicle-dropdown-div .add-vehicle-button .add-vehicle, .main-content .vehicle-dropdown-div .add-vehicle-button .link-icon {
    width: 18px;
    height: 18px
}

.main-content .address-list::-webkit-scrollbar {
    height: 8px
}

.main-content .address-list::-webkit-scrollbar-track {
    margin: 0px 20px;
    border-radius: 10px;
    background: #f5f5f5
}

.main-content .address-list::-webkit-scrollbar-thumb {
    background: #2c358a;
    border-radius: 10px
}

.main-content ::-webkit-scrollbar {
    width: 8px
}

.main-content ::-webkit-scrollbar-track {
    margin: 0px 20px;
    border-radius: 10px;
    background: #f5f5f5
}

.main-content ::-webkit-scrollbar-thumb {
    background: #2c358a;
    border-radius: 10px
}

@keyframes spin {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg)
    }

    to {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg)
    }
}

@keyframes spin-back {
    from {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg)
    }

    to {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg)
    }
}

.main-content .info {
    width: 15px;
    height: 15px
}

.main-content .weight-div {
    width: 80px;
    height: 95px;
    background: #f5f5f5;
    outline: 2px solid #d8d9da;
    border-radius: 5px;
    position: absolute;
    transform: translate(-3px, 75px);
    overflow: hidden
}

.main-content .weight-div ul {
    padding: 10px 10px 5px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center
}

.main-content .weight-div ul li {
    padding: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3a3a3a;
    border-radius: 5px
}

.main-content .weight-div ul li:hover {
    color: #fff;
    background: #2c358a
}

@keyframes spin {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg)
    }

    to {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg)
    }
}

@keyframes spin-back {
    from {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg)
    }

    to {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg)
    }
}


.main-content .fields-wrapper .field .input-label {
    color: #000;
    font-size: 17px;
    font-weight: 600;
    display: flex;
    margin-bottom: 5px;
    width: 240px;
    gap: 10px;
    justify-content: flex-start;
    align-items: center
}

.input-label {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    width: 250px
}

.tracking-div {
    display: flex;
    align-items: center
}

.tracking-link {
    color: #3a3a3a;
    margin: 10px 0;
    text-decoration: underline;
    width: 50%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden
}

.tracking-link a {
    text-wrap: nowrap
}

.tracking-link.live-status {
    color: #3a3a3a;
    margin: 10px 0;
    width: 90%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    text-decoration: none !important
}

.track-img {
    width: 24px;
    height: 24px;
    margin: 2px 10px 0
}

.no-underline {
    text-decoration: none
}

.align-center {
    display: flex;
    align-items: center
}

.edit-button {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    display: none
}

.time-details {
    text-decoration: none;
    font-weight: 500
}

.time-input-label {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    margin-bottom: 5px;
    width: 230px;
    gap: 10px;
    justify-content: flex-start;
    align-items: center
}

.time-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap
}

.time-wrapper .fields-wrapper {
    width: 49%;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    padding: 15px 0px 0px 20px;
    border-radius: 10px
}

.separator {
    margin: 0 10px
}

.time-details:hover .edit-button {
    display: block
}

.time-entry-div {
    width: 230px;
    height: 50px;
    border-radius: 10px;
    outline: 0;
    padding: none;
    color: #3a3a3a;
    font-size: 16px;
    margin-bottom: 20px;
    border: none;
    text-overflow: ellipsis
}

.fields-wrapper.new-thread .thread-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70px;
    transform: translateX(-20px);
    height: 80px
}

.padding-right-1{
  padding-right: 45px !important;
}

.fields-wrapper.new-thread .thread-div .time-spent-div {
    font-size: 16px;
    color: #2c358a
}

.fields-wrapper.new-thread .thread-div .thread {
    width: inherit;
    height: 2px;
    background: repeating-linear-gradient(90deg, #2c358a 0 5px, #fff 0 12px);
    border-radius: 1px
}

.filler-4 {
    width: 230px;
    height: auto
}

.translateX-none {
    transform: translateX(0px) !important
}

.margin-bottom-4 {
    margin-bottom: 20px
}

.dispatch-truck {
    width: 20px;
    height: auto
}

.time-date-div {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
    height: 50px
}

.date {
    padding: 10px 5px;
    border-radius: 5px;
    background: #f5f5f5;
    border: none;
    color: #2c358a;
    cursor: pointer;
    font-size: 14px
}

.date:hover {
    border: 1.5px solid #7959A1
}

.time {
    padding: 10px 5px;
    border-radius: 5px;
    background: #f5f5f5;
    border: none;
    color: #2c358a;
    cursor: pointer;
    font-size: 13px
}

.time:hover {
    border: 1px solid #EA9A96
}

.field-wrapper.tracking-heading {
    display: flex
}

.field-wrapper.tracking-heading .field {
    width: 400px
}

.underline {
    text-decoration: underline !important;
    cursor: pointer
}

.margin-right {
    margin-right: 100px !important
}

.chevron{
  width: 15px;
    height: auto;
    margin: 10px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
}

.main-content {
    width: 100%;
    height: auto
}

.main-content .header-wrapper .left-header-2 {
    display: flex
}

.main-content .header-wrapper .left-header-2 .status-label {
    font-size: 20px;
    font-weight: 700;
    color: #000
}

.main-content .header-wrapper .left-header-2 .status-value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    height: 30px;
    padding: 5px 10px;
    margin: 0 10px;
    background: #e6e6e6;
    border-radius: 50px;
    outline: 2px solid #aaa
}

.main-content .header-wrapper .left-header-2 .status-value .status-text {
    color: #aaa;
    font-size: 16px
}

.main-content .header-wrapper .right-header-2 {
    display: flex;
    background: #f5f5f5;
    border-radius: 10px;
    padding: 5px 8px
}

.main-content .header-wrapper .right-header-2 div {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    border-radius: 8px;
    cursor: pointer
}

.main-content .header-wrapper-2 .right-header-2 .booking-tab .tracking-tab {
    background: none
}

.main-content .header-wrapper-2 .right-header-2 .active-tab {
  background: #2c358a none!important;
}



.main-content .header-wrapper .right-header-2 img {
    width: 24px;
    height: 24px
}

.main-content .header-wrapper .right-header-2 .switch-text {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #2c358a
}

.main-content .link-arrow {
    width: 20px;
    height: 20px;
    margin: 0px 0 0 10px
}

.main-content .align-center {
    display: flex;
    align-items: center
}

.main-content select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none
}

.main-content .select-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  /* transform: translateX(-10px); */
}

.translateX-10{
  transform: translateX(-10px);
}

.main-content .select-wrapper select {
    cursor: pointer
}

.main-content .select-wrapper .chevron-div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.main-content .select-wrapper .chevron-div .chevron {
    width: 20px;
    height: 20px
}

.main-content .p-absolute {
    position: absolute
}

.main-content .pointer {
    cursor: pointer
}



.main-content .header-wrapper-2 .right-header-2 .switch-text {
    color: #2c358a
}

.main-content .header-wrapper-2 .right-header-2 .switch-text-active {
  color: #fff!important;
}



/* Main-content-2 */



.pointer {
    cursor: pointer;
  }
  
  .main-content-2 {
    width: 100%;
    height: auto;
  }
  .main-content-2 .top-header {
    padding: 0px 0px;
    font-family:'Hanken Grotesk', san-seri;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main-content-2 .top-header .page-heading {
    color: #2c358a;
    font-size: 22px;
    font-weight: 600;
  }
  .main-content-2 .top-header .search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .main-content-2 .top-header .search-wrapper .search-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .main-content-2 .top-header .search-wrapper .search-div .search-img-div {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
  }
  .main-content-2 .top-header .search-wrapper .search-div .search-img-div .search-img {
    width: 22px;
    height: 22px;
  }
  .main-content-2 .top-header .search-wrapper .search-div .search-contracts {
    position: relative;
    width: 400px;
    height: 50px;
    border-radius: 50px;
    background: #f5f5f5;
    color: #3a3a3a;
    padding: 0 130px 0 50px;
    border: none;
  }
  .main-content-2 .top-header .search-wrapper .search-div .search-contracts:focus {
    border: 2px solid #2c358a;
    outline: none;
  }
  .main-content-2 .top-header .sort-div {
    position: absolute;
  }
  .main-content-2 .top-header .sort-div .sort-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    height: 50px;
    background: #2c358a;
    border-radius: 0 50px 50px 0;
    cursor: pointer;
  }
  .main-content-2 .top-header .sort-div .sort-part .sort-option {
    font: 16px 600;
    color: #ffffff;
    padding-left: 20px;
  }
  .main-content-2 .top-header .sort-div .sort-part .search-chevron-wrapper {
    width: 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-content-2 .top-header .sort-div .sort-part .search-chevron-wrapper .chevron-white {
    width: 15px;
    height: auto;
    margin: 0 10px;
  }
  .main-content-2 .top-header .sort-div .sort-dropdown {
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 10px;
    padding: 10px 10px 5px 10px;
    position: absolute;
    margin-top: 10px;
    width: 120px;
  }
  .main-content-2 .top-header .sort-div .sort-dropdown .sort-options {
    padding: 5px 5px 5px 15px;
    margin-bottom: 5px;
    height: 40px;
    width: auto;
    color: #3a3a3a;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .main-content-2 .top-header .sort-div .sort-dropdown .sort-options:hover {
    background: #2c358a;
    color: #ffffff;
    border-radius: 5px;
  }
  .main-content-2 .top-header .sort-div .sort-dropdown .sort-options-active {
    background: #2c358a;
    color: #ffffff;
    border-radius: 5px;
  }
  .main-content-2 .whole-table {
    margin: 0px 40px 20px 40px;
    border: 2px solid #d8d9da;
    border-radius: 20px;
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .main-content-2 .whole-table .heading-row {
    display: flex;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-radius: 20px 20px 0 0;
    border-bottom: 2px solid #d8d9da;
  }
  .main-content-2 .whole-table .heading-row .heading-table-cells {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    color: #000000;
    height: 50px;
  }
  .main-content-2 .whole-table .heading-row .heading-table-cells .sort-blue {
    width: 20px;
    height: 20px;
    margin-left: 2px;
    cursor: pointer;
  }
  .main-content-2 .whole-table .heading-row .heading-filler-start {
    width: 50px;
    height: 50px;
  }
  .main-content-2 .whole-table .heading-row .heading-filler-end {
    width: 120px;
    height: 50px;
  }
  .main-content-2 .whole-table .heading-row .contract-reference {
    width: 12%;
  }
  .main-content-2 .whole-table .heading-row .pickup-address, .main-content-2 .whole-table .heading-row .delivery-address {
    width: 15%;
  }
  .main-content-2 .whole-table .heading-row .created-date, .main-content-2 .whole-table .heading-row .effective-date, .main-content-2 .whole-table .heading-row .valid-upto {
    width: 10%;
  }
  .main-content-2 .whole-table .heading-row .status {
    width: 8%;
  }
  .main-content-2 .whole-table .table-wrapper {
    height: 65vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .main-content-2 .whole-table .table-wrapper .table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100.7%;
    /* height: 50px; */
    border-radius: 20px 20px 0 0;
    border-bottom: 2px solid #d8d9da;
    flex-wrap: wrap;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .table-cells {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #3a3a3a;
    /* height: 50px; */
  }
  .main-content-2 .whole-table .table-wrapper .table-row .address-div {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 20px 0px 50px;
    border-radius: 10px;
    padding: 5px 15px;
    outline: 2px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .address-div .add-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .address-div .add-block .add-heading {
    font-size: 14px;
    color: #3a3a3a;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .address-div .add-block .add-div {
    padding: 10px;
    width: 250px;
    max-width: 250px;
    overflow-y: scroll;
    height: 100px;
    background: #f5f5f5;
    border-radius: 10px;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .address-div .add-block .add-div .address {
    font-size: 14px;
    color: #3a3a3a;
    font-weight: 500;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .address-div .thread-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .address-div .thread-div .thread {
    width: inherit;
    height: 1px;
    background: repeating-linear-gradient(90deg, #d8d9da 0 5px, #ffffff 0 12px);
    border-radius: 1px;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .heading-filler-start {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .heading-filler-start .chevron-grey {
    width: 20px;
    height: 20px;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .heading-filler-end {
    width: 120px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .heading-filler-end .book-button {
    height: 35px;
    background: #2c358a;
    border-radius: 7px;
    padding: 5px 15px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    font-family: "Hanken Grotesk", sans-serif;
    display: flex;
    align-items: center;
    border: none;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .heading-filler-end .book-button .save-booking-white {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .contract-reference {
    width: 12%;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .pickup-address, .main-content-2 .whole-table .table-wrapper .table-row .delivery-address {
    width: 15%;
    cursor: pointer;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .pickup-address span, .main-content-2 .whole-table .table-wrapper .table-row .delivery-address span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .created-date, .main-content-2 .whole-table .table-wrapper .table-row .effective-date, .main-content-2 .whole-table .table-wrapper .table-row .valid-upto {
    width: 10%;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .status {
    width: 8%;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .status .active-status {
    border-radius: 30px;
    border: 1.5px solid #65B429;
    background: #D1E9BF;
    color: #65B429;
    padding: 2px 8px;
    font-size: 15px;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .status .expired-status {
    border-radius: 30px;
    border: 1.5px solid #aaaaaa;
    background: #e6e6e6;
    color: #3a3a3a;
    padding: 2px 8px;
    font-size: 15px;
  }
  .main-content-2 .whole-table .footer-row {
    border-top: 1px solid #d8d9da;
    border-radius: 0 0 20px 20px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-content-2 .whole-table .footer-row .left-footer {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  .main-content-2 .whole-table .footer-row .left-footer .rows-per-page {
    color: #3a3a3a;
    font-size: 16px;
    font-weight: 600;
    margin: 0 20px 0 0;
  }
  .main-content-2 .whole-table .footer-row .left-footer .row-select-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }
  .main-content-2 .whole-table .footer-row .left-footer .row-select-wrapper .no-of-rows {
    padding: 2px 25px 2px 5px;
    border: 1px solid #d8d9da;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
  }
  .main-content-2 .whole-table .footer-row .left-footer .row-select-wrapper .no-of-rows:hover {
    border: 2px solid #d8d9da;
  }
  .main-content-2 .whole-table .footer-row .left-footer .row-select-wrapper .no-of-rows:focus-visible {
    border: none;
    outline: 2px solid #d8d9da;
  }
  .main-content-2 .whole-table .footer-row .left-footer .row-select-wrapper .chevron-grey-2 {
    width: 15px;
    height: auto;
    margin: 0 5px;
    position: absolute;
    pointer-events: none;
  }
  .main-content-2 .whole-table .footer-row .right-footer {
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 50px;
  }

  .margin-right-5{
    margin-right: 7% !important;
  }

  .prev, .next{
    width: 18px;
    height: 18px;
  }

  .main-content-2 .whole-table .footer-row .right-footer .no-of-pages {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
  }
  .main-content-2 .whole-table .footer-row .right-footer .chevron-grey-3 {
    width: 18px;
    transform: rotate(90deg);
    margin-bottom: 3px;
    cursor: pointer;
  }
  .main-content-2 .whole-table .footer-row .right-footer .chevron-grey-4 {
    width: 18px;
    transform: rotate(-90deg);
    margin-bottom: 3px;
    cursor: pointer;
  }
  
  .height-120 {
    height: fit-content !important;
    
  }
  
  .height-200 {
    height: fit-content !important;
  }

  .height-300 {
    height: fit-content !important;
    padding: 0px 0 20px 0 !important;
  }
  
  .white-space-normal {
    white-space: normal !important;
    
  }

  .height-fit{
    padding:10px !important;
    height:fit-content !important;
  }
  
  .rotate-arrow {
    transform: rotate(180deg);
    transition: all 0.3s;
  }
  
  .rotate-back {
    transform: rotate(0deg);
    transition: all 0.3s;
  }
  
  .transform-down {
    transform: translateY(70px);
  }
  
  .visibility-hidden {
    visibility: hidden;
  }
  
  .opacity-less {
    opacity: 0.3;
  }
  
  .no-of-rows {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }
  
  .address-div::-webkit-scrollbar {
    height: 8px;
    cursor: pointer;
  }
  
  .address-div::-webkit-scrollbar-track {
    margin: 0px 20px;
    border-radius: 10px;
    background: #f5f5f5;
    cursor: pointer;
  }
  
  .address-div::-webkit-scrollbar-thumb {
    background: #2c358a;
    border-radius: 10px;
    cursor: pointer;
  }
  
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    /* margin: 0px 20px; */
    border-radius: 10px;
    background: #f5f5f5;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #2c358a;
    border-radius: 10px;
  }


  
.main-content-3 {
    width: 100%;
    height: auto;
  }
  .main-content-3 .top-header {
    padding: 10px 40px;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main-content-3 .top-header .page-heading {
    color: #2c358a;
    font-size: 22px;
    font-weight: 600;
  }
  .main-content-3 .top-header .switch-content {
    display: flex;
    background: #f5f5f5;
    border-radius: 10px;
    padding: 5px 8px;
    width:550px;
    justify-content: space-between;
  }
  .main-content-3 .top-header .switch-content div {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
  }
  .main-content-3 .top-header .switch-content .in-progress-tab {
    background: #2c358a;
  }
  .main-content-3 .top-header .switch-content .in-progress-tab .switch-text {
    color: #ffffff;
  }
  .main-content-3 .top-header .switch-content img {
    width: 24px;
    height: 24px;
  }
  .main-content-3 .top-header .switch-content .switch-text {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #2c358a;
  }
  .main-content-3 .top-header .search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .main-content-3 .top-header .search-wrapper .search-div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .main-content-3 .top-header .search-wrapper .search-div .search-img-div {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
  }
  .main-content-3 .top-header .search-wrapper .search-div .search-img-div .search-img {
    width: 22px;
    height: 22px;
  }
  .main-content-3 .top-header .search-wrapper .search-div .search-contracts {
    position: relative;
    width: 300px;
    height: 50px;
    border-radius: 50px;
    background: #f5f5f5;
    color: #3a3a3a;
    padding: 0 20px 0 50px;
    border: none;
  }
  .main-content-3 .top-header .search-wrapper .search-div .search-contracts:focus {
    border: 2px solid #2c358a;
    outline: none;
  }
  .main-content-3 .top-header .sort-div {
    position: absolute;
  }
  .main-content-3 .top-header .sort-div .sort-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
    height: 50px;
    background: #2c358a;
    border-radius: 0 50px 50px 0;
    cursor: pointer;
  }
  .main-content-3 .top-header .sort-div .sort-part .sort-option {
    font: 16px 600;
    color: #ffffff;
    padding-left: 20px;
  }
  .main-content-3 .top-header .sort-div .sort-part .search-chevron-wrapper {
    width: 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-content-3 .top-header .sort-div .sort-part .search-chevron-wrapper .chevron-white {
    width: 15px;
    height: auto;
    margin: 0 10px;
  }
  .main-content-3 .top-header .sort-div .sort-dropdown {
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 10px;
    padding: 10px 10px 5px 10px;
    position: absolute;
    margin-top: 10px;
    width: 120px;
  }
  .main-content-3 .top-header .sort-div .sort-dropdown .sort-options {
    padding: 5px 5px 5px 15px;
    margin-bottom: 5px;
    height: 40px;
    width: auto;
    color: #3a3a3a;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .main-content-3 .top-header .sort-div .sort-dropdown .sort-options:hover {
    background: #2c358a;
    color: #ffffff;
    border-radius: 5px;
  }
  .main-content-3 .whole-table {
    margin: 0px 40px 20px 40px;
    border: 2px solid #d8d9da;
    border-radius: 20px;
    width: auto;
    display: flex;
    flex-direction: column;
  }
  .main-content-3 .whole-table .heading-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-radius: 20px 20px 0 0;
    border-bottom: 2px solid #d8d9da;
  }
  .main-content-3 .whole-table .heading-row .heading-table-cells {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    color: #000000;
    height: 50px;
  }
  .main-content-3 .whole-table .heading-row .heading-table-cells .sort-blue {
    width: 20px;
    height: 20px;
    /* margin-left: 10px; */
    cursor: pointer;
  }
  .main-content-3 .whole-table .heading-row .heading-filler-start {
    width: 50px;
    height: 50px;
  }
  .main-content-3 .whole-table .heading-row .heading-filler-end {
    width: 40px;
    height: 50px;
  }
  .main-content-3 .whole-table .heading-row .booking-id {
    width: 10%;
  }
  .main-content-3 .whole-table .heading-row .customer-ref-no {
    width: 10%;
  }
  .main-content-3 .whole-table .heading-row .vehicle-type {
    width: 10%;
  }
  .main-content-3 .whole-table .heading-row .pickup-address, .main-content-3 .whole-table .heading-row .delivery-address {
    width: 12%;
  }
  .main-content-3 .whole-table .heading-row .booking-date, .main-content-3 .whole-table .heading-row .pickup-date {
    width: 7%;
  }
  .main-content-3 .whole-table .heading-row .status {
    width: 11%;
  }
  .main-content-3 .whole-table .heading-row .track {
    width: 5%;
  }
  .main-content-3 .whole-table .table-wrapper {
    height: 65vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .main-content-3 .whole-table .table-wrapper .table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100.7%;
    /* height: 50px; */
    border-radius: 20px 20px 0 0;
    border-bottom: 2px solid #d8d9da;
    flex-wrap: wrap;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #3a3a3a;
    /* height: 50px; */
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells .underline {
    text-decoration: underline;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells .booking-link {
    width: 20px;
    height: 20px;
    margin-left: 0px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells .track-button {
    width: 40px;
    height: 40px;
    background: #2c358a;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells .track-button .track-img {
    width: 25px;
    height: 25px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells.heading-filler-start {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells.heading-filler-start .chevron-grey {
    width: 20px;
    height: 20px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells.heading-filler-end {
    width: 40px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells.heading-filler-end .action-dropdown {
    position: absolute;
    width: 140px;
    height: 100px;
    transform: translate(-115px, 65px);
    background: #f5f5f5;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells.heading-filler-end .action-dropdown1 {
    height: 50px!important;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells.heading-filler-end .action-dropdown .clone, .main-content-3 .whole-table .table-wrapper .table-row .table-cells.heading-filler-end .action-dropdown .cancel {
    width: 120px;
    height: 40px;
    cursor: pointer;
    color: #3a3a3a;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 10px;
    border-radius: 8px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells.heading-filler-end .action-dropdown .clone:hover, .main-content-3 .whole-table .table-wrapper .table-row .table-cells.heading-filler-end .action-dropdown .cancel:hover {
    background: #2c358a;
    color: #ffffff;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .table-cells.heading-filler-end .menu-dots {
    width: 25px;
    height: 25px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .booking-id {
    width: 10%;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .customer-ref-no {
    width: 10%;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .vehicle-type {
    width: 10%;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .booking-date, .main-content-3 .whole-table .table-wrapper .table-row .pickup-date {
    width: 7%;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .track {
    width: 5%;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .pickup-address, .main-content-3 .whole-table .table-wrapper .table-row .delivery-address {
    width: 12%;
    cursor: pointer;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .vehicle-type span, .main-content-3 .whole-table .table-wrapper .table-row .customer-ref-no span, .main-content-3 .whole-table .table-wrapper .table-row .pickup-address span, .main-content-3 .whole-table .table-wrapper .table-row .delivery-address span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .status {
    width: 11%;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .status .ytc-status {
    border-radius: 30px;
    border: 1.5px solid #aaaaaa;
    background: #e6e6e6;
    color: #3a3a3a;
    padding: 2px 8px;
    font-size: 13px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .status .i-n-status {
    border-radius: 30px;
    border: 1.5px solid #EBB02D;
    background: #F9E8C0;
    color: #EBB02D;
    padding: 2px 8px;
    font-size: 13px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .status .cm-status {
    border-radius: 30px;
    border: 1.5px solid #65B429;
    background: #D1E9BF;
    color: #65B429;
    padding: 2px 8px;
    font-size: 13px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .status .r-a-p-status {
    border-radius: 30px;
    border: 1.5px solid #526D82;
    background: #CCD4DA;
    color: #526D82;
    padding: 2px 8px;
    font-size: 13px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .status .cn-status {
    border-radius: 30px;
    border: 1.5px solid #FE3839;
    background: #FFC4C4;
    color: #FE3839;
    padding: 2px 8px;
    font-size: 13px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .address-div {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 20px 0px 50px;
    border-radius: 10px;
    padding: 5px 15px;
    outline: 2px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .address-div .add-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .address-div .add-block .add-heading {
    font-size: 14px;
    color: #3a3a3a;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .address-div .add-block .add-div {
    padding: 10px;
    width: 250px;
    max-width: 250px;
    overflow-y: scroll;
    height: 100px;
    background: #f5f5f5;
    border-radius: 10px;
  }
  .main-content-2 .whole-table .table-wrapper .table-row .address-div .add-block .add-div{

  }
   
    /* width */
.add-div::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.add-div::-webkit-scrollbar-track {
  background: #f5f5f5;
  height: 50px;
}

/* Handle */
.add-div::-webkit-scrollbar-thumb {
  background: #2C358A;
}

/* Handle on hover */
.add-div::-webkit-scrollbar-thumb:hover {
  background: #2c358a;
}

  .main-content-3 .whole-table .table-wrapper .table-row .address-div .add-block .add-div .address {
    font-size: 14px;
    color: #3a3a3a;
    font-weight: 500;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .address-div .thread-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  .main-content-3 .whole-table .table-wrapper .table-row .address-div .thread-div .thread {
    width: inherit;
    height: 1px;
    background: repeating-linear-gradient(90deg, #d8d9da 0 5px, #ffffff 0 12px);
    border-radius: 1px;
  }
  .main-content-3 .whole-table .footer-row {
    border-top: 1px solid #d8d9da;
    border-radius: 0 0 20px 20px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-content-3 .whole-table .footer-row .left-footer {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  .main-content-3 .whole-table .footer-row .left-footer .rows-per-page {
    color: #3a3a3a;
    font-size: 16px;
    font-weight: 600;
    margin: 0 20px 0 0;
  }
  .main-content-3 .whole-table .footer-row .left-footer .row-select-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
  }
  .main-content-3 .whole-table .footer-row .left-footer .row-select-wrapper .no-of-rows {
    padding: 2px 25px 2px 5px;
    border: 1px solid #d8d9da;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
  }
  .main-content-3 .whole-table .footer-row .left-footer .row-select-wrapper .no-of-rows:hover {
    border: 2px solid #d8d9da;
  }
  .main-content-3 .whole-table .footer-row .left-footer .row-select-wrapper .no-of-rows:focus-visible {
    border: none;
    outline: 2px solid #d8d9da;
  }
  .main-content-3 .whole-table .footer-row .left-footer .row-select-wrapper .chevron-grey-2 {
    width: 15px;
    height: auto;
    margin: 0 5px;
    position: absolute;
    pointer-events: none;
  }
  .main-content-3 .whole-table .footer-row .right-footer {
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 50px;
  }
  .main-content-3 .whole-table .footer-row .right-footer .no-of-pages {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
  }
  .main-content-3 .whole-table .footer-row .right-footer .chevron-grey-3 {
    width: 18px;
    transform: rotate(90deg);
    margin-bottom: 3px;
    cursor: pointer;
  }
  .main-content-3 .whole-table .footer-row .right-footer .chevron-grey-4 {
    width: 18px;
    transform: rotate(-90deg);
    margin-bottom: 3px;
    cursor: pointer;
  }
  
  

  /* Media Queries */

  @media screen and (min-width: 769px) {
    .modal-card-content {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 1300px) {
    .whole-table{
      margin: 0px 0px 0px 0px !important;
    }
    .whole-table1{
      margin: 0px!important;
    }
  }

  @media screen and (min-width: 1400px) {
    .whole-table{
      margin: 0px 0px 0px 0px !important;
    }
    .whole-table1{
      margin: 0px!important;
    }
  }

  .column.is-2::-webkit-scrollbar{
    width: 1px;
  }


  /* width */
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
.column.is-2::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.column.is-2::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
.column.is-2::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.main-contet-padding{
  padding: 0 10px !important;
}

.remove-padding{
  padding: 0 !important;
}

.width-170{
  width: 180px !important;
}

.font-18{
  font-size: 18px !important;
}
.contact-images{
  width: 15px;
  height: 15px;

}
.margin-top-12{
  margin-top: 12px;
}
.margin-top-20{
  margin-top: 20px;
}


.remove-country-code{
    color: #3A3A3A !important;
    background: none !important;
    border: none !important;
    outline: none !important;
    background: none !important;
}
.input-field-margin-none{
  width: 240px;
  height: 40px;
  border-radius: 8px;
  padding: 0 6px 0 12px;
  color: #3a3a3a;
  font-size: 16px;
  /* margin-bottom: 6px; */
  border: none;
}

.aligncenter{
  align-items: center;
}

.wrap-ellipsis{
  white-space: nowrap;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.padding-left-60{
  padding-left: 50px !important;
}
.padding-left-30{
  padding-left: 30px !important;
}

.width-270{
  width: 270px !important;
}
.justify-content-end{
  /* display: flex; */
  justify-content: flex-end;
}

.clock{
  width: 16px;
    height: 16px;
    pointer-events: none;
    /* position: absolute; */
    transform: translate(155px, -27px);
  
}
.clock1{
  width: 16px;
    height: 16px;
    pointer-events: none;
    /* position: absolute; */
    transform: translate(155px, -27px);
  
}

.inputtime[type="time"] {
  position: relative;
  cursor: pointer;
}

.inputtime[type="time"]::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: transparent;
  cursor: pointer;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.main-content .buttons-div .trigger-form-button {
  padding: 10px 20px;
  width: -moz-fit-content;
  width: fit-content;
  height: 40px;
  background: #ffa500;
  color: #fff;
  outline: 2px solid #ffa50094;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
}
.select:not(.is-multiple):not(.is-loading)::after{
  z-index: unset;
}
.button-caf {
  border: 1px solid #ffffff;
  border-radius: 8px;
   /* height: 35px; */
   color: "white";
   position: "relative";
   /* width: 140px; */
   /* padding: 0.1%; */
   padding: 0.2% 0.3%;
   margin-left: 2%;
  background-color: #e6c58c;
  font-size: 0.6em;
}

.button-caf-gst {
  border: 1px solid #1f28a2;
  border-radius: 8px;
  padding: 0.8%;
   /* height: 35px; */
   color: #1f28a2;
   position: "relative";
  /* background-color: #c29548; */

}

.wd_80 {
  width: 80%;
}
.table_rej tbody td{
padding: 1%;
}
.wd_35 {
  width: 35%;
}
.wd_20 {
  width: 20%;
}
.ml_50 {
  margin-left: 40%;
}
.table_caf {
  width: 75%;
  border: 1px solid black;
}
.table_caf_fin {
  width: 100%;
  border: 1px solid black;
}
.table_caf td {
  width: 33%;
  border-right: 1px solid black;
  /* padding: 1% 2%; */
}
.table_caf_fin td {
  width: 23%;
  border-right: 1px solid black;
  height: 60px;
  
  /* padding: 1% 2%; */
}
.table_caf tr , .table_caf thead ,.table_caf_fin tr , .table_caf_fin thead{
  /* padding: 2% 5%; */
  border-bottom: 1px solid black;
}
.table_caf thead td , .table_caf_fin thead td{
  font-weight: 700;
  font-weight: bold;
  padding: 1% 2%;
  background-color: #b5b3ba;
}
.button_cancel {
  background-color: #32a893;
  border: none;
  padding: 1%;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  margin-top: 5%;
  margin-left: 83%;
  width: 70px;
  cursor: pointer;
}
.button_submit {
  background-color:#32a893;
  border: none;
  padding:1%;
   border-radius: 8px;
   color:white;
   font-weight: 600;
   margin-top: 5%;
   margin-bottom: 2%;
   /* width: 70px; */
   cursor: pointer;
   margin-left: 2%;
}
.inp_table_caf {
  width: 100%;
  height: 100%;
  padding: 3% 4%; 
  border: none;
}
.radio_btn {
  padding: 4% 6%;
  width: 85px;
  border: 1px solid rgb(35, 35, 105);
  border-radius: 5px;
}
.btn_selected {
  background-color: #2C358A;
  color: white;
}
.wd_100 {
  width: 100% !important ;
}
.listentityapprovaltable >  tr:hover {
  background-color: #eea724 !important;
  cursor: pointer;
  color: white;
}
.input-danger {
  border: 1px solid red;
}
.inp_wd_80 {
  width: 80%;
}
.statusbtn {
  /* border: 1px solid green; */
  border-radius: 8px;
   /* color: rgb(241, 175, 33); */
   position: "relative";
   padding: 0.2% 0.5%;
   margin-left: 2%;
  font-size: 0.6em;
  text-wrap: nowrap;
  /* width: 40%; */
}
.btn_status_span_credit {
  width: 50%;
}
.credit_title_spn {
  text-wrap: nowrap;
}
.out_none_inp {
  outline: none;
}
.timeline_table {
  /* max-height: 100px; */
  max-height: 20em;
  display: inline-block;
  overflow: scroll;
}
.contNumcodespn {
  color: #000;
}
.wd_101 {
  width: 22em !important ;
  border-right: none;
}
.remarks-badge{
  padding: 1rem;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin: 1rem 5rem;
}
.select:disabled{
  border: none !important;
  background-color: #ebebeb;
}
.checkbox-label{
  font-size: 14px;
    font-weight: 600;
}
.entitiesV2List>tr:hover {
  background-color: #eea724 !important;
  cursor: pointer;
  color: white;
} 
.tooltipTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  position: relative;
}
.adduserpopup input {
  /* outline: none; */
  /* border-radius: 2px; */
}

.approve-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 30%;
  /* top:100% ; */
  transform: translate(50%,0);
}

.btn_height{
  height: 50px;
    width: 100px;
}

#userdacbtn {
  border: 1px solid red;
  color: red;
  margin-left: -7%;
}
/* .conwd_80 {
  width: 80%;
} */
.exit_icon {
  margin-right: 10%;
  font-size: 18px;
}

.main-content .fields-wrapper .field .input-disabled-wrap {
  width: 350px !important;
  height: 40px !important;
  border-radius: 10px;
  outline: 0;
  display: flex;
  align-items: center;
  color: #3a3a3a;
  font-size: 16px;
  /* margin-bottom: 20px; */
  border: none;
  padding: 0 6px 0 15px;
  text-overflow: ellipsis;
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
}
.dtselectclass{
  border:1px solid #D8D9DA;
  border-radius: 4px;
}

.shipper_container{
  display: flex;
  width: 100%;
  align-items: center;
  gap: .3rem;
  flex-wrap: wrap;
  margin: 0 3rem;
  margin-top: 1rem;
  column-gap: 1rem;
}

.wd_30{
  width: 30%;
}

.m0{
  margin: 0;
}

.trackshipment_btns_container{
  display: flex;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: 4%;
  right: 5%;
}

.exist_customer_entity_wrapper{
  position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(0%, -50%);
}

.exist_customer_entity_container{
  min-height: 250px;
  min-width: 800px;
  background-color: #fff;
  /* border: 1px solid rgb(121, 119, 121); */
  border-radius: 4px;
  box-shadow: 0 0 2px #786e6e;
  padding: 1rem;
}


.exist_customer_entity_container table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 1rem;
 
}
.exist_customer_entity_container tbody{
  box-shadow: 0 0 1px #515151;
}
.exist_customer_entity_container table tr th:nth-child(1) {
  border-top-left-radius: 10px
}
.exist_customer_entity_container table tr th:nth-child(3) {
  border-top-right-radius: 10px
}
.exist_customer_entity_container td, .exist_customer_entity_container th {
  text-align: left;
  padding: 8px;
}
.exist_customer_entity_container th {
  background-color: #2d48a6;
  color: #fff;
}


.flexWithCenter{
  display: flex;
  align-items: center;
  gap: 1rem;
}
#entitySettingsMenu ul li {
  cursor: pointer;
  padding: 1%;
  border-radius: 5px;
  padding: 4% 4%;
  line-height: "2px";
}
#entitySettingsMenu ul li:hover {
  background-color: #706b6b;
  color: whitesmoke;
}

.exist_customer_entity_main_wrapper{
      position: absolute;
      top: 2%;
      /* left: -20%; */
      right: 0;
      width: 83vw;
      height: 100vh;
      background-color: #f4f4f478;
}

.dsrConfigPg {
  padding: 1%;
  padding-bottom: 3%;
}

.button-cancel {
  display: flex;
  float: right;
  margin-bottom: 15%;
  margin-right: 1rem;
  margin-top: 5%;
  width: 70px;
  height: 40px;
  background: #E6E6E6;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2C358A;
  cursor: pointer;
  border: none;
  padding: 1%;
}

/* Submit Button */
.button-submit {
  display: flex;
  float: right;
  margin-top: 5%;
  margin-bottom: 15%;
  margin-right: 1rem;
  width: 70px;
  height: 40px;
  left: 0px;
  top: 0px;
  bottom: 20px;
  background: #2C358A;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  cursor: pointer;
  border: none;
}

.selectedmilestonecon {
  padding: 1%;
  background-color: #e2eaf5;
  border-radius: 10px;
  
}
.selectedmilestoneconheader {
  background-color: #b8ccea;
  padding: 0.5%;
  border-radius: 10px;
}
#entitySettingsMenu:not(:hover ) {
  display: none
}

.css-swicq1-MultiValueGeneric {
  overflow: hidden ;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 55% !important;
  padding: 3px;
  padding-left: 6px;
  box-sizing: border-box;
  background-color: lightgray;
}
.css-oqvjly-option {
  cursor: default;
  display: block;
  font-size: .75rem !important;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 2px 12px !important;
  box-sizing: border-box;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
.blinking {
  animation: blink 1s infinite; /* Adjust duration as needed */
}
.dropdown-menu {
  display: none;
}

.dropdown-menu.visible {
  display: block;
}


.table-cell {
  border: 1px solid black;
  background-color: #f8f8f8;
  height: 40px;
}
.table-header-cell {
  padding: 15px;
  border: 1px solid black;
  background-color:#C2C1C8;
  font-weight: bold;
  height: 40px;
}
.table-cell-centered {
  padding: 10px;
  border: 1px solid black;
  background-color: #f8f8f8;
  text-align: center;
  height: 40px;
}
.full-width-collapsed {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 16px;
}

.width-product{
width: 180px;
}

.product1 {
  display: inline-block;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f8f8f8;
  position: relative;
  
}

.product1.error {
  border: 1px solid red;
}
.product1.normal {
  border: 1px solid #aaa;
}

.table-inputa {
  width: 100%;
  padding: 18px;
  border: 1px solid #f8f8f8;
  border-radius: 4px;
  background-color: #f8f8f8;
}
.table-inputb {
  width: 100%;
  padding: 18px;
  border: 1px solid #aaa;
  border-radius: 4px;
  background-color: #f8f8f8;
}
.table-head3 {
  padding: 15px;
  border: 1px solid black;
  background-color: #C2C1C8;
  font-weight: bold;
  height: 40px;
}
.custom-cell {
  border: 1px solid black;
  background-color: #f8f8f8;
  position: relative;
  height: 40px;
  text-align: center
}
