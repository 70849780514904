#mix1,#mix2,#mix3,#mix4,#mix5{
    color:#2c358a;
    cursor:pointer
}
#mix1:hover,#mix2:hover,#mix3:hover,#mix4:hover,#mix5:hover,#mix6:hover{
    color:#ed1878;
    cursor:pointer;
    text-decoration: underline;
}
#mix6{
    color:#f00;
    cursor: pointer;
}
